import styled, { css } from 'styled-components';
import { spacing } from '../../../styles/settings/spacing';
import { above } from '../../../styles/tools/media';
import breakpoints from '../../../styles/settings/breakpoints';
import { Container } from '../../../styles/generic/container';

type CustomEmbedProps = {
	article?: boolean;
	fullWidth?: boolean;
};

const CustomEmbed__Wrapper = styled(Container)<CustomEmbedProps>`
	padding-top: ${spacing[32]};
	margin-bottom:  ${spacing[32]};

	${above(breakpoints[768], () => css`
	padding-top: ${spacing[64]};
	margin-bottom:  ${spacing[64]};
	`)}

	${(props) =>
		props.article === true && css`
			padding: 0 !important;
			margin: 0 !important;
		`
	}

	${(props) =>
		props.fullWidth === true && css`
			padding: 0 !important;
			margin: 0 !important;
		`
	}
`;

export default { CustomEmbed__Wrapper };
