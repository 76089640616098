/* eslint-disable react/no-danger */
import React from 'react';
import IframeResizer from 'iframe-resizer-react';

import Styled from './index.styled';

type CustomEmbedProps = {
	iframe?: {
		title?: string;
		src?: string;
		resize?: boolean;
	};
	embedSnippet?: string;
	isArticlePage?: boolean;
	fullWidth?: boolean;
	anchorId?: string;
};

const CustomEmbed = ({
	iframe,
	embedSnippet,
	isArticlePage,
	fullWidth,
	anchorId,
}: CustomEmbedProps) => {
	function createMarkup(snippet: string) {
		return {
			__html: snippet,
		};
	}
	return (
		<Styled.CustomEmbed__Wrapper
			article={isArticlePage}
			fullWidth={fullWidth}
			as={!isArticlePage ? 'section' : 'div'}
			id={anchorId || ''}
		>
			{iframe &&
				(iframe.resize ? (
					<IframeResizer
						width="100%"
						height="auto"
						frameBorder="0"
						src={iframe.src}
						title={iframe.title}
						heightCalculationMethod="bodyScroll"
						autoResize
					/>
				) : (
					<iframe
						width="100%"
						height="auto"
						frameBorder="0"
						src={iframe.src}
						title={iframe.title}
					/>
				))}

			{embedSnippet && <div dangerouslySetInnerHTML={createMarkup(embedSnippet)} />}
		</Styled.CustomEmbed__Wrapper>
	);
};

export default CustomEmbed;
